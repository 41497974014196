/** @jsx jsx */

import React, { PropsWithChildren } from "react";
import { css, jsx, Global } from "@emotion/react";

export const Layout: React.FC<PropsWithChildren> = (props) => (
  <div css={css``}>
    <Global
      styles={css`
        @font-face {
          font-family: Kobe;
          src: url("./fonts/Kobe.otf") format("opentype");
        }

        html,
        body {
          width: 100%;
          height: 100%;

          background: #1ae26a;
          font-family: "Kobe", sans-serif;
          font-size: 38px;
          line-height: 1.2;
          background-image: url("images/bg.png");
          background-position: center;
          background-size: "cover";
        }
      `}
    />
    {props.children}
  </div>
);
