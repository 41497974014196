import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import { Layout } from "../components/Layout";
import ReactHowler from "react-howler";
import { navigate } from "gatsby";

const PageTemplate: React.FC = (data) => {
  console.log({ data });
  const {
    pageContext: {
      node: { title, content, nextPage },
    },
  } = data;

  return (
    <Layout>
      <h1>{title}</h1>
      {content.map((item, i) => {
        return (
          <div key={`content-item-${i}`}>
            {item.strapi_component === "content.text" && (
              <ReactMarkdown
                children={item.content.data.content}
                remarkPlugins={[remarkGfm]}
              />
            )}
            {item.strapi_component === "content.audio" && (
              <ReactHowler
                playing={true}
                src={item.file.localFile.publicURL}
                onEnd={() => {
                  if (nextPage) {
                    navigate(`/${nextPage.slug}`);
                  }
                }}
              />
            )}
          </div>
        );
      })}
    </Layout>
  );
};

export default PageTemplate;
